const header = document.querySelector("body > header");
const container =
  document.querySelector<HTMLElement>("body > .page-body.body-bg-darkgrey") ??
  document.querySelector<HTMLElement>("body > .container");
const main = container?.querySelector("main");

if (header && container && main) {
  const setContainerPadding = () => {
    const headerHeight = header.getBoundingClientRect().height;
    const currentTop = main.getBoundingClientRect().top;

    // Buffer to not jump for micro adjustments
    if (headerHeight > currentTop - 10 || headerHeight < currentTop) {
      container.style.paddingTop = `${headerHeight}px`;
    }
  };

  setContainerPadding();

  const announcementBar =
    header.querySelector<HTMLDivElement>(".announcement-bar");

  if (announcementBar) {
    const closeButton = document.querySelector(".announcement-bar__close button");

    if (!closeButton) {
      throw new Error("Announcement bar has no close button");
    }

    closeButton.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      announcementBar.style.display = "none";
      setContainerPadding();

      const expireTime = new Date(Date.now() + 1000 * 24000);
      document.cookie = `ad_seen=true;expires=${expireTime.toUTCString()};path=/;secure;`;
    });
  }
}
